@import 'layout';

// Variables
//
// Add global variables here, color palette, those variables shared by components all applied to global styling

// ====================================================================
// Breakpoints
//
// Window breakpoints
// ====================================================================
$layout-mobile: map-get($layout, mobile) + px;
$layout-tablet: map-get($layout, tablet) + px;
$layout-desktop: map-get($layout, desktop) + px;

// ====================================================================
// Base palette
//
// Grayscale and brand colors for use across the site.
// ====================================================================
$pure-black: #000;
$pure-white: #fff;

// Corporate colors
$white: #f8f7f2;
$black: #0d0d0d;
$gold: #ac7908;

// Secondary colors
$darker-gold: #8c5d00;
$soft-gold: #e1d1ac;
$dark-grey: #6d6d6d;
$soft-grey: #bdbcb8;
$text-grey: #747474;

$filter-gold: invert(38%) sepia(77%) saturate(2463%) hue-rotate(31deg) brightness(101%)
  contrast(94%);
$filter-white: invert(1);

// ====================================================================
// General
//
// Global color variables, used usually in sections
// Global variables used across the web app
// ====================================================================
$page-bg-color: $white;
$element-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.04);

// ====================================================================
// Nav bar
// ====================================================================
$navbar-height-mobile: px(64);
$navbar-height-desktop: px(80);
$scroll-shift: px(20);

// ====================================================================
// List of z-index element
// ====================================================================
$elements: pages, filter-nav, cookie-banner, main-nav, banner;

// ====================================================================
// Animations
// ====================================================================
$default-ease: cubic-bezier(0.3, 1, 0.8, 1);
$default-duration: 0.568s;

$horizontal-shift-ease: cubic-bezier(0.02, 0, 0.08, 0.94);

$image-hover-duration: 0.7s;
$image-scale: scale(1.05);
$image-hover-ease: cubic-bezier(0.02, 0.03, 0.08, 0.96);
$image-transition: transform $image-hover-duration $image-hover-ease;

$carousel-duration: 0.4s;

// ====================================================================
// Grid
// ====================================================================
$side-padding-mobile: px(16);
$side-padding-tablet: px(24);

$vertical-padding-mobile: px(80);
$vertical-padding-desktop: px(100);

// ====================================================================
// Spacing
// ====================================================================
$min-height-media-text-tablet: px(534);
$min-height-media-text-desktop: px(688);

// ====================================================================
// Widths
// ====================================================================
$default-max-width: px(533);
$default-max-width-desktop: px(575);
$extended-max-width: px(688);

// ====================================================================
// Cards
// ====================================================================
$min-height-cards-tablet: px(492);
$min-height-cards-mobile: px(506);
$min-height-cards-desktop: px(428);
