@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('~@/assets/fonts/Manrope/subset-Manrope-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Manrope';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url('~@/assets/fonts/Manrope/subset-Manrope-Bold.woff2') format('woff2');
}
