@import 'shared';

.MobileNav {
  @include box(100%);
  @include flex-center-vert;
  height: $navbar-height-mobile;
  margin-bottom: auto;
  padding: 0 $side-padding-mobile;
  z-index: 2;
  position: relative;

  &.transparent {
    background: none;
    border-color: rgba($soft-grey, 0);

    ul {
      li {
        > a,
        button {
          color: $white;
        }
      }

      .circle {
        background-color: rgba($soft-grey, 0);
        border-color: $white;

        svg {
          path {
            stroke: $white;
          }
        }
      }
    }

    .logo {
      path {
        fill: $white;
      }
    }

    .hamburger {
      .line {
        background-color: $white;
      }
    }
  }

  .logoCon {
    height: 100%;
    z-index: 1;

    .logo {
      height: 100%;
      padding: px(12) 0;

      path {
        transition: fill $default-duration $default-ease;
      }
    }
  }

  .hamburger {
    height: 100%;
    margin-left: auto;
    @include flex-center-horiz;
    flex-direction: column;
    width: px(20);
    z-index: 1;
    position: relative;
    cursor: pointer;

    .line {
      width: 100%;
      height: 1px;
      right: 0;
      background-color: $black;
      display: block;
      position: absolute;
      transition: background-color $default-duration $default-ease;

      &:first-child {
        transform: translateY(-5px);
      }
      &:last-child {
        transform: translateY(5px);
      }
    }
  }

  .wrapper {
    --animated-height: #{calc(#{vh(100)} - var(--banner-height))};

    position: absolute;
    width: 100%;
    height: var(--animated-height);
    padding-top: $navbar-height-mobile;
    left: 0;
    top: 0;
    z-index: 0;
    pointer-events: none;

    &.open {
      pointer-events: initial;
    }
    // background-color: $black;

    .overlay {
      background-color: $black;
      position: absolute;
      visibility: hidden;
      @include box(100%);
      left: 0;
      top: 0;
      transform: translateY(-100%);
    }

    .menu {
      z-index: 1;
      position: relative;
      height: 100%;
      padding: px(38) $side-padding-mobile;
      display: flex;
      flex-direction: column;

      ul {
        display: flex;
        flex-direction: column;

        li {
          margin: px(7) 0;

          &:not(.hasChildren) {
            &.activeRoute a {
              color: $gold;
              border-color: $gold;
            }
          }

          a {
            @include reset-button;
            @include typography-h3;
            padding: px(4) 0;
            color: $white;
            transition: color $default-duration $default-ease,
              border-color $default-duration $default-ease;
            border-bottom: rgba($gold, 0) solid 1px;
          }

          .link {
            color: $soft-grey;
            margin-top: px(22);
            @include typography-paragraph-2;
          }

          &.hasChildren {
            .hasChildren {
              background: none;
              border: none;

              .childrenItems {
                display: flex;
                flex-direction: column;

                a {
                  padding: px(20) 0;
                  display: block;
                  @include flex-center-vert;
                  width: 100%;
                  position: relative;

                  span {
                    @include underline(rgba($soft-grey, 0.5));
                  }

                  &.activeRoute {
                    color: $gold;
                  }

                  svg {
                    transform: rotateZ(-90deg);
                    width: px(20);
                    margin-left: auto;
                    margin-right: px(6);

                    path {
                      stroke: $gold;
                      stroke-width: 1.5;
                    }
                  }
                }
              }
            }
          }
        }

        &.externalLinks {
          margin-top: auto;

          li {
            margin-bottom: 0;
            a {
              display: flex;
            }
          }
        }
      }
    }
  }
}
