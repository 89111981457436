@import 'shared';

.Banner {
  background-color: $black;
  color: $white;
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  padding-left: $side-padding-mobile;
  height: px(76);
  width: 100%;
  gap: px(30);
  position: relative;
  @include z-index(banner);

  @include media-tablet {
    height: px(60);
    gap: px(40);
    padding-left: $side-padding-tablet;
    padding-right: $side-padding-tablet;
  }

  .textContainer {
    padding: px(16) 0;
    display: grid;
    grid-template-rows: auto auto;
    gap: px(6);

    @include media-tablet {
      grid-template-rows: unset;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: px(40);
    }
  }

  .title {
    @include font-family($manrope, 700);
    @include font-size(10, 12, 0.05em);
    text-transform: uppercase;
    color: $white;
    white-space: nowrap;
  }

  .announcement {
    @include font-family($manrope, 700);
    @include font-size(10, 14, 0.05em);
    text-transform: uppercase;
    color: $soft-grey;
    @include text-ellipsis(2, 1);

    @include media-desktop {
      max-width: px(900);
    }
  }

  a.announcement {
    text-decoration: underline;
  }

  .btnContainer {
    width: px(57);
    @include flex-center;
    border-left: 1px solid rgba($white, 0.1);

    @include media-tablet {
      border-left: none;
    }
  }

  .close {
    @include reset-button;

    transition-property: color, background-color, border-color;
    transition-duration: $default-duration;
    transition-timing-function: $default-ease;

    @include media-tablet {
      background-color: transparent;
      @include box(px(38));

      border: 1px solid rgba($white, 0.2);
      border-radius: 50%;
      @include flex-center;
    }

    &:hover {
      @include media-tablet {
        color: $black;
        background-color: $white;
        border-color: $white;
      }
    }
  }

  .svg {
    @include box(px(18));

    @include media-tablet {
      @include box(px(16));
    }
  }
}
