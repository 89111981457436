.wp-block-jam3-company-info {
  max-width: px(220);

  .wp-block-jam3-company-info-item {
    margin-bottom: px(24);

    .title {
      margin-bottom: 0;
    }
  }
}

.bio_investments_wrap {
  max-width: px(340);

  a {
    text-underline-offset: px(4);
    text-decoration: underline;
  }
}

.wp-block-table {
  overflow-y: hidden;
  padding-bottom: px(20);
  @include discreet-scrollbar;

  table {
    thead {
      border-bottom: 1px solid $dark-grey;
      color: $darker-gold;
      @include typography-label;

      th,
      td {
        padding: px(15) 0;
      }
    }

    tbody {
      overflow: hidden;
      color: $black;
      @include typography-paragraph-2;

      th,
      td {
        padding: px(20) 0;
      }

      tr:not(:last-child) {
        border-bottom: 1px solid $soft-grey;
      }
    }

    tfoot {
      color: $black;
      @include typography-paragraph-2;
      border-top: 1px solid $dark-grey;
      border-bottom: 1px solid $dark-grey;

      th,
      td {
        padding: px(15) 0;
      }
    }

    th,
    td {
      text-align: left;
      border: none;
    }
  }

  figcaption {
    @include typography-label;
    color: $darker-gold;
    text-align: left;
    margin-top: px(16);
  }

  &.is-style-stripes {
    tbody tr:nth-child(odd) {
      background-color: rgba($soft-gold, 0.2);
    }
  }
}
