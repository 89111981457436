@use 'sass:math';

$default-font-size-percent: 62.5;
$default-font-size-px: 10;

// we need to convert percentages so that html font size is set in px, otherwise Safari won't scale below 9px ignoring %
$percent-to-px-multiplier: $default-font-size-px / $default-font-size-percent;

// ====================================================================
// RANGES
// HTML font size will proportionately scale up or down within the allowed range
// ====================================================================

// below this size we lock font size at its min
$min-scale-size: 1920;

// above this size we lock font size at its max
$max-scale-size: 3840;

// ====================================================================
// CALCULATED VALUES
// ====================================================================

@mixin main() {
  font-size: #{$default-font-size-px}px;
  $min-size-math: $default-font-size-percent;
  $variable-size-math: $default-font-size-percent / $min-scale-size * 100;
  $max-size-math: $default-font-size-percent / $min-scale-size * $max-scale-size;

  @media (min-width: #{$min-scale-size}px) {
    $min-size: #{$min-size-math * $percent-to-px-multiplier}px;
    $variable-size: #{$variable-size-math * $percent-to-px-multiplier}vw;
    $max-size: #{$max-size-math * $percent-to-px-multiplier}px;

    font-size: clamp($min-size, $variable-size, $max-size);
  }
}
