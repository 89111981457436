@import 'shared';

// Global colors classes
// Text Colors
.has-primary-color {
  color: $gold;
}
.has-secondary-color {
  color: $darker-gold;
}
.has-tertiary-color {
  color: $soft-gold;
}
.has-quaternary-color {
  color: $dark-grey;
}
.has-quinary-color {
  color: $soft-grey;
}
.has-foreground-color {
  color: $black;
}
.has-background-color {
  color: $white;
}

// Bg Colors
// NOTE: Special case for newsletter block
.has-background {
  @include full-width-breakout;
  padding: px(60) $side-padding-mobile;

  @include media-tablet {
    padding: px(60) $side-padding-tablet;
  }

  @include media-desktop {
    @include reset-breakout;
    padding: px(75) px(115);
  }
}

.has-primary-background-color {
  background-color: $gold;
}
.has-secondary-background-color {
  background-color: $darker-gold;
}
.has-tertiary-background-color {
  background-color: $soft-gold;
}
.has-quaternary-background-color {
  background-color: $dark-grey;
}
.has-quinary-background-color {
  background-color: $soft-grey;
}
.has-foreground-background-color {
  background-color: $black;
}
.has-background-background-color {
  background-color: $white;
}

// Text Align
.text-align-left {
  margin-right: auto;
  text-align: left;
}

.text-align-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.text-align-right {
  margin-left: auto;
  text-align: right;
}

// Paragraph and list styles
.is-style-paragraph-2 {
  @include typography-paragraph-2;

  li {
    @include typography-paragraph-2;
  }
}

.is-style-paragraph-3 {
  @include typography-paragraph-3;

  li {
    @include typography-paragraph-3;
  }
}

.is-style-tiny {
  @include typography-label;

  li {
    @include typography-label;
  }
}

p.is-style-legal {
  color: $dark-grey;
  max-width: none;
  @include font-size(12, 16);

  @include media-desktop {
    line-height: px(20);
  }
}

p.line-indicator {
  --width: #{px(50)};
  --offset: #{px(-10)};

  position: relative;

  @include media-tablet {
    &::before {
      content: '';
      position: absolute;
      left: calc(var(--offset) - var(--width));
      top: 0.75em;
      width: var(--width);
      height: 1px;
      background-color: $gold;
    }
  }

  @include media-tablet-landscape {
    --width: #{px(60)};
    --offset: #{px(-12)};
  }

  @include media-desktop {
    --width: #{px(80)};
    --offset: #{px(-16)};
  }
}

// NOTE: Margin utility classes up to 100px
@for $i from 1 through 10 {
  .mb-#{$i * 10} {
    margin-bottom: px($i * 10);
  }
}

@for $i from 1 through 10 {
  .mt-#{$i * 10} {
    margin-top: px($i * 10);
  }
}
