@import 'shared';

.Newsletter {
  width: 100%;

  .title {
    margin-bottom: px(15);
    @include typography-h3;
    max-width: px(280);

    @include media-desktop {
      max-width: px(380);
      margin-bottom: px(40);
    }
  }

  .form {
    width: 100%;
    max-width: px(570);
    padding-bottom: px(8);

    .email {
      @include flex-center;
      position: relative;
      .underline {
        @include underline($black);
      }
      &::after {
        content: '';
        @include underline;
        transition: transform $default-duration $default-ease;
      }

      input {
        @include reset-button;
        padding: px(25) 0;
        flex: 1;
        @include typography-label;
        cursor: auto;
        &::placeholder {
          color: $dark-grey;
        }
      }

      .submit {
        cursor: pointer;
        pointer-events: none;
        &:hover {
          border-color: $gold;
        }
      }
      @include circle-icon(false, true);

      .circle {
        border-color: $dark-grey;
      }
      .validateText {
        @include typography-label;
        margin-right: px(16);
      }

      &.hasInput {
        .submit {
          pointer-events: all;
        }
        &::after {
          transform: scaleX(1);
        }
      }

      &.isValid {
        &::after {
          transform: scaleX(1);
        }
        .validateText {
          color: $gold;
        }
        .submit {
          pointer-events: none;
          background-color: $gold;
          border-color: transparent;
          svg {
            width: px(16);
          }
          path {
            stroke: $white;
          }
        }
      }

      &.isInvalid {
        &::after {
          transform: scaleX(0);
        }
        .validateText {
          color: $dark-grey;
        }
        .submit {
          pointer-events: none;
          background-color: $dark-grey;
          border: none;
          path {
            fill: $white;
          }
        }
      }
    }
  }

  &.light {
    .title {
      color: $white;
    }

    .form {
      .email {
        .underline {
          background-color: $white;
        }
        input {
          &::placeholder {
            color: $soft-grey;
          }
        }
        .submit {
          border-color: $soft-grey;
          padding: 0;
          svg {
            display: flex;
            width: px(20);
            path {
              stroke: $white;
            }
          }
          &:hover {
            border-color: $gold;
          }
        }
        &.isValid {
          .submit {
            border-color: transparent;
          }
        }
        &.isInvalid {
          .validateText {
            color: $soft-grey;
          }
          svg {
            path {
              fill: $white;
              stroke: none;
            }
          }
        }
      }
    }
  }
}
