@import 'shared';

$cookie-transition: $default-duration $default-ease;

body {
  #onetrust-consent-sdk {
    opacity: 0;
    animation: cookie-fade-in $cookie-transition forwards;
    animation-delay: 0.2s;
    @include z-index(cookie-banner);
    position: relative;

    @keyframes cookie-fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    #onetrust-banner-sdk {
      background-color: $pure-white;
      bottom: 1em;
      left: 1em;
      max-width: px(370);
      box-shadow: none;
      border: 1px solid rgba($soft-grey, 0.5);
      transition: border-color $cookie-transition;

      &:focus,
      &:focus-visible {
        outline: none;
        border: 1px solid rgba($black, 0.5);
      }

      #onetrust-group-container {
        margin-top: 0;
        #onetrust-policy {
          margin-top: px(2);

          .banner-header {
            display: none;
          }

          #onetrust-policy-text {
            color: $dark-grey;
            padding: px(20) px(15) 0 px(15);
            margin-right: 5%;
          }
        }
      }

      #onetrust-button-group-parent {
        padding: px(20) px(15);

        #onetrust-button-group {
          display: flex;
          flex-wrap: wrap;
          gap: px(10) px(20);
          width: unset;

          .banner-actions-container {
            display: flex;
            flex-wrap: wrap;
            gap: px(10) px(20);
          }

          #onetrust-accept-btn-handler,
          #onetrust-reject-all-handler,
          #onetrust-pc-btn-handler {
            @include reset-button;
            @include typography-label;
            text-decoration: underline;
            text-underline-offset: 4px;
            white-space: nowrap;
            width: unset;
          }
        }
      }

      #onetrust-close-btn-container {
        right: px(3);
        top: px(10);

        .onetrust-close-btn-handler {
          @include box(px(40));
          background-size: px(10);
        }
      }
    }

    #onetrust-pc-sdk {
      box-shadow: none;
      border: none;
      transition: border-color $cookie-transition;

      @include media-mobile {
        height: calc(100% - #{$navbar-height-mobile});
        bottom: 0;
        top: unset;
      }

      *:focus {
        outline: none;
      }

      &:focus,
      &:focus-visible {
        outline: none;
        border: 1px solid rgba($black, 0.5);
      }

      .ot-pc-header {
        min-height: unset;
        .ot-pc-logo {
          display: none;
        }

        .ot-title-cntr {
          width: calc(100% - #{px(20)});

          #ot-pc-title {
            @include typography-paragraph-2;
            color: $black;
          }
        }
      }

      .ot-active-menu {
        border-width: px(5);

        &:focus {
          outline: 1px solid rgba($black, 0.5);
        }
      }

      #ot-pc-content {
        .ot-cat-grp {
          .ot-abt-tab,
          .ot-cat-item {
            h3 {
              @include typography-paragraph-3;
              color: $dark-grey;
            }
          }
        }

        .ot-desc-cntr {
          #ot-pvcy-hdr,
          .ot-cat-header {
            @include typography-label;
            color: $black;
          }
          .ot-category-desc {
            @include typography-paragraph-3;
            color: $dark-grey;
          }
        }
      }

      .ot-pc-footer {
        .ot-pc-footer-logo {
          display: none;
        }

        .ot-btn-container {
          button {
            @include typography-label;
            padding: px(5) px(15);
            transition: opacity $cookie-transition;

            @include media-tablet {
              margin: px(8);
            }
          }
        }
      }
    }
  }

  #ot-sdk-btn-floating {
    .ot-floating-button__front,
    .ot-floating-button__back {
      box-shadow: none;
      border: none;
    }

    .ot-floating-button__front {
      background-color: none;
      background-image: url('~@/assets/images/cookies-open.png');
    }

    .ot-floating-button__back {
      background-color: none;
      background-image: url('~@/assets/images/cookies-close.png');

      svg {
        display: none;
      }
    }

    &.ot-floating-button {
      bottom: px(15) !important;
      left: px(15) !important;
      @include box(px(35));
    }

    &::after {
      @include typography-label;
      text-transform: uppercase !important;
    }
  }
}
