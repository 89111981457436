// Globals
//
// Global styling, normalize overwritten
@use 'fonts';
@use 'utils/scalable-fonts';

@import 'shared';
@import 'wordpress-classes';
@import 'wordpress-components';
@import 'cookiepro';

html {
  // stylelint-disable-next-line
  --scrollbar-width: 0px;

  // stylelint-disable-next-line
  --banner-height: 0px;

  @include scalable-fonts.main;

  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  text-size-adjust: none;

  &.hide-static-html {
    visibility: hidden;
    opacity: 0;
  }
}

body {
  width: 100%;
  margin: 0;
  padding: 0;

  word-break: keep-all;

  @include typography-paragraph-1;
  background-color: $page-bg-color;
  overscroll-behavior: none;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: vh(100);
  background-color: $page-bg-color;
  z-index: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body.cover-hero {
  main {
    margin-top: -#{$navbar-height-mobile};

    @include media-desktop {
      margin-top: -#{$navbar-height-desktop};
    }
  }
}

div.banner,
nav,
footer,
main {
  isolation: isolate;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

:is(li, dt, dd, h1, h2, h3, h4, h5, h6, p, blockquote, figure) {
  margin: 0;
  padding: 0;
}

button,
input,
a {
  &:focus:not(:focus-visible) {
    outline: none;
  }
}

pre {
  white-space: pre-wrap;
}

:is(h1, h2, h3, h4, h5, h6) {
  @include font-header;
  word-break: break-word;
}

h1 {
  @include typography-h1;
  max-width: px(750); // 42ch
}

h2 {
  @include typography-h2;
  max-width: px(700); // 38ch
}

h3 {
  @include typography-h3;
}

h4 {
  @include typography-h4;
}

li {
  @include typography-paragraph-1;
  
  &.has-small-font-size {
    @include typography-paragraph-2;
  }
}

mark {
  background-color: transparent;
}

sub,
sup {
  line-height: 0;
}

:is(h5, h6) {
  @include typography-paragraph-1;
}

:is(h1, h2, h3, h4, h5, h6, p) {
  a {
    color: $darker-gold;
    text-decoration: underline;
  }
}

main {
  min-height: vh(100);
  @include z-index(pages);

  > * {
    @include vertical-spacing;

    &:not(.cover-hero, .blog-hero):first-child {
      margin-top: $vertical-padding-mobile;

      @include media-desktop {
        margin-top: $vertical-padding-desktop;
      }
    }
  }

  // NOTE: Special case for full-width images
  > figure {
    @include media-mobile {
      margin: 0;
    }
  }

  @include wrapper;
}

section {
  scroll-margin-top: calc(#{$navbar-height-mobile} + #{$scroll-shift});

  @include media-desktop {
    scroll-margin-top: calc(#{$navbar-height-desktop} + #{$scroll-shift});
  }
}
