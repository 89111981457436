@import 'shared';

.Footer {
  background-color: $black;
  color: $white;
  width: 100%;

  span.circle {
    border-color: $dark-grey;
  }
  .wrapper {
    padding-top: px(50);
    @include wrapper;

    @include media-tablet {
      padding-top: px(80);
    }
  }

  .footerTop,
  .footerMiddle,
  .footerBottom {
    display: flex;

    @include media-tablet {
      gap: px(16);
      & > * {
        &:first-child {
          flex: 0.7;
        }
        &:nth-child(2) {
          flex: 1;
        }
      }
    }
  }

  .footerTop {
    @include typography-h3;
    flex-wrap: wrap;

    .companyName {
      width: 100%;
      margin-bottom: px(55);

      @include media-tablet {
        margin-bottom: 0;
      }
    }
    .newsletterWrapper {
      width: 100%;
    }
    .title {
      max-width: px(100);
    }
    .eclipse {
      margin-top: px(24);

      @include media-tablet {
        margin-top: px(54);
      }

      &::before,
      &::after {
        @include box(px(16));
        display: inline-block;
        margin-right: px(4);
        content: '';
        border-radius: 50%;
      }

      &::before {
        background-color: $white;
      }

      &::after {
        background-color: $black;
        border: 1px solid $white;
      }
    }
  }

  .footerMiddle {
    padding-top: px(75);
    padding-bottom: px(80);

    @include media-tablet {
      padding-top: px(72);
      padding-bottom: px(104);
    }
    .contactInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      @include typography-paragraph-2;
      flex: 1;

      .address {
        margin-bottom: px(14);
      }

      .phone {
        margin-bottom: px(6);
      }

      @include media-tablet {
        flex: 0.7;
        .address {
          margin-bottom: px(20);
        }
        .phone {
          margin-bottom: px(12);
        }
      }
    }

    .menuLinks {
      display: flex;
      align-items: flex-end;
      flex: 1;

      ul {
        flex: 1;
        li {
          margin-top: px(14);
          a {
            display: flex;
          }
        }

        &.socialLinks {
          flex: 0.4;
          &::after {
            background-color: $white;
          }

          li {
            margin-top: px(22);

            &:first-child {
              margin-top: 0;
            }

            @include media-tablet {
              margin-top: px(8);
            }

            @include media-desktop {
              margin-top: px(6);
            }
          }
        }
        &.externalLinks {
          display: none;

          @include media-tablet {
            display: block;
          }
        }
      }
    }
  }

  .footerBottom {
    border-top: 1px solid rgba($dark-grey, 0.5);
    padding: px(24) 0 px(22);
    align-items: center;
    position: relative;
    flex-wrap: wrap;

    @include media-tablet {
      padding: px(28) 0;
    }

    .inner {
      @include typography-label;

      span {
        margin-right: px(3);
      }
    }

    .links {
      order: 1;
      width: 100%;
      border-bottom: 1px solid rgba($dark-grey, 0.5);
      display: flex;
      padding-bottom: px(28);
      margin-top: px(-10);

      li {
        margin-right: px(28);
      }

      @include media-tablet {
        border: none;
        padding-bottom: 0;
        order: 2;

        li {
          margin-right: 0;
          @include flex-center-vert;

          a {
            display: inline-flex;
          }
        }
        & > * {
          &:first-child {
            flex: 0.4;
          }
          &:nth-child(2) {
            flex: 1;
          }
        }
      }
    }
    .left {
      order: 2;
      width: 100%;
      @include flex-center-vert;
      padding-top: px(22);

      @include media-tablet {
        order: 1;
        padding-top: 0;
      }

      .backToTop {
        @include reset-button;
        @include typography-label;
        @include circle-icon(true, true);
        @include flex-center-vert;
        margin-left: auto;

        @include media-tablet {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        .circle {
          margin-left: px(16);
          border-color: $dark-grey;

          svg {
            transform: rotateZ(180deg);
            path {
              stroke-width: 3;
            }
          }
        }

        &:hover {
          .circle {
            background-color: $darker-gold;
          }
        }
      }
    }
  }
}
