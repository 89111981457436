@import 'shared';

.ThemedButton {
  --svg-bg-color: #{$gold};
  --svg-play-color: #{$white};

  position: relative;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  @include typography-label;
  transition: border-color $default-duration $default-ease;

  &:hover {
    cursor: pointer;
  }

  .circle {
    @include box(px(24));
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid $dark-grey;
    @include flex-center;
    transition-property: background-color, border-color;
    transition-timing-function: $default-ease;
    transition-duration: $default-duration;
  }

  .svg {
    transition: color $default-ease $default-duration;
    @include box(px(10));
  }

  &.primary {
    background-color: transparent;
    border-radius: px(40);
    border: 1px solid $gold;
    padding: px(7) px(10) px(7) px(20);
    gap: px(10);

    @include media-tablet {
      gap: px(12);
      padding: px(11) px(10) px(11) px(20);
    }

    @include media-desktop {
      gap: px(16);
      padding: px(11) px(16) px(11) px(24);
    }

    &:hover {
      --svg-bg-color: #{$white};
      --svg-play-color: #{$gold};

      .circle {
        border-color: transparent;
        background-color: $gold;
      }

      .svg {
        color: $white;
      }
    }
  }

  &.secondary {
    padding: px(14) 0;
    gap: px(42);
    border-bottom: 1px solid currentColor;
    .svg {
      transition: transform $default-ease $default-duration;
    }

    &:hover,
    &.active {
      border-color: $gold;
      .svg {
        transform: translateX(px(-5));

        &.external {
          transform: translate(px(2), px(-2));
        }
      }
    }
  }

  &.action {
    padding-bottom: px(4);
    margin-top: px(4);

    @include media-desktop {
      padding-bottom: px(6);
      margin-top: px(6);
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $black;
      transition: background-color $default-duration $default-ease;
    }

    &.light {
      &::after {
        background-color: $white;
      }
    }

    &:hover {
      &::after {
        background-color: $gold;
      }
    }
  }

  &.icon {
    @include typography-link-icon;
    gap: px(9);
    .svg {
      transition: transform $default-ease $default-duration;
    }
    &:hover {
      .svg {
        transform: translate(px(2), px(-2));
      }
    }
  }
}
