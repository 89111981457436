// Sass Mixins
//
// More information about mixins in:
// http://sass-lang.com/documentation/file.SASS_REFERENCE.html#mixins
// Add comment to every function
@import 'vars';

/// Improve animation performance for containers
/// @example scss Input
///   .card {
///     @include gpu();
///   }
@mixin gpu {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000px;

  .safari & {
    perspective: none; // otherwise text and icons become blurry
  }
}

/// Set exactly the same width and height of a component, avoid repeat the same dimension
/// @parameter {Measure unit} $width - Specify width of the box
/// @parameter {Measure unit} $height [$width] - Specify height of the box, the default value is the width
/// @example scss Input
///   .card {
///     @include box(3.2rem);
///   }
///   .card-another {
///     @include box(3.2rem, 2rem);
///   }
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

/// Convert the pixels to rem for in a specific resolution, usually 1440px wide
/// @example scss Input
///   section {
///     height: px(200);
///   }
@function px($px) {
  @return $px * 0.1rem;
}

/// Set top and left to 0
/// @example scss Input
///   element {
///     @include position-0;
///   }
@mixin position-0 {
  top: 0;
  left: 0;
}

/// Set full size of box and position
/// @parameter {string} $pos - one of relative, absolute, fixed. relative by default
/// @example scss Input
///   element {
///     @include position-100(absolute);
///   }
@mixin position-100($pos: relative) {
  position: $pos;
  @include position-0;
  @include box(100%);
}

/// Set display flex and center children horizontally and vertically
/// @example scss Input
///   element {
///     @include flex-center;
///   }
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/// Set display flex and center children vertically
/// @example scss Input
///   element {
///     @include flex-center-vert;
///   }
@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

/// Set display flex and center children horizontally
/// @example scss Input
///   element {
///     @include flex-center-horiz;
///   }
@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}

/// Center its element (horizontally and vertically) by using transform
/// @example scss Input
///   element {
///     @include transform-center;
///   }
@mixin transform-center {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/// Set vertical center of its element
/// @example scss Input
///   element {
///     @include transform-center-vert;
///   }
@mixin transform-center-vert {
  top: 50%;
  transform: translateY(-50%);
}

/// Set horizontal center of its element
/// @example scss Input
///   element {
///     @include transform-center-horiz;
///   }
@mixin transform-center-horiz {
  left: 50%;
  transform: translateX(-50%);
}

/// Set z-index value in order of $element variable
/// @example scss Input
///   element {
///     @include z-index(preloader);
///   }
@mixin z-index($id) {
  z-index: index($elements, $id);
}

/// Reset button's look
/// @example scss Input
///   button {
///     @include reset-button;
///   }
@mixin reset-button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  text-align: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  text-transform: inherit;
  display: inline-block;
  margin: 0;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: currentColor;
}

// Shorthand to use CSS var --vh
// @example scss Input
//   section {
//     height: vh(5);
//   }
@function vh($vh) {
  @return calc(var(--vh) * #{$vh});
}

/// Add layout wrapper
/// @example scss Input
///   .layout {
///     @include wrapper;
///   }
@mixin wrapper($max-width: px(1392)) {
  margin: 0 auto;
  max-width: $max-width;
  padding-left: $side-padding-mobile;
  padding-right: $side-padding-mobile;

  @include media-tablet {
    padding-left: $side-padding-tablet;
    padding-right: $side-padding-tablet;
  }

  @include media-desktopLg {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin blog-wrapper {
  margin: 0 auto;
  max-width: $extended-max-width;
}

/// Add layout wrapper
/// @example scss Input
///   .parent {
///     width: 60%
///     .breakout-child {
///       @include full-width-breakout
///     }
///   }
@mixin full-width-breakout {
  margin-left: -$side-padding-mobile;
  width: calc(100vw - var(--scrollbar-width));

  @include media-tablet {
    margin-left: -$side-padding-tablet;
  }

  @include media-desktop {
    margin-left: 0;
    @include transform-center-horiz;
  }
}

@mixin right-bleed-breakout {
  margin-right: calc(50% - 50vw + var(--scrollbar-width) / 2);
}

@mixin left-bleed-breakout {
  margin-left: calc(50% - 50vw + var(--scrollbar-width) / 2);
}

@mixin reset-breakout {
  left: 0;
  transform: none;
  margin-left: unset;
  margin-right: unset;
  width: unset;
}

@mixin vertical-spacing {
  margin-bottom: px(80);

  @include media-desktop {
    margin-bottom: px(100);
  }
}

@mixin container {
  max-width: $default-max-width;

  @include media-desktop {
    max-width: $default-max-width-desktop;
  }
}

@mixin circle-icon($small: false, $grey-border: false, $hover: true) {
  --bg-svg: #{$white};
  --color-svg: #{$black};
  @if ($grey-border) {
    --border-svg: #{$soft-grey};
  } @else {
    --border-svg: #{$dark-grey};
  }

  .circle {
    @if ($small) {
      @include box(px(20));

      @include media-desktop {
        @include box(px(24));
      }
    } @else {
      @include box(px(25));

      @include media-desktop {
        @include box(px(40));
      }
    }
    &.focus-visible {
      outline: 2px solid red;
    }

    flex-shrink: 0;
    background-color: rgba(var(--bg-svg), 0);
    border: 1px solid var(--border-svg);
    @include flex-center;
    border-radius: 50%;

    transition-property: background-color, border-color;
    transition-duration: $default-duration;
    transition-timing-function: $default-ease;
  }

  .svg {
    transition: color $default-duration $default-ease;
    @include box(px(10));

    @include media-desktop {
      @include box(px(15));
    }
    path {
      transition: stroke $default-duration $default-ease, fill $default-duration $default-ease;
    }
    // color: var(--color-svg);
  }
  @if ($hover) {
    &:hover {
      --bg-svg: #{$gold};
      --color-svg: #{$white};
      --border-svg: #{transparent};
    }
  }
}

@mixin max-height-minus-nav {
  max-height: calc(#{vh(100)} - #{$navbar-height-mobile});

  @include media-desktop {
    max-height: calc(#{vh(100)} - #{$navbar-height-desktop});
  }
}

// NOTE: General to be added to containers in pages
@mixin general-spacing-styles {
  h1 {
    margin-bottom: px(20);

    @include media-desktop {
      margin-bottom: px(42);
    }
  }

  h2 {
    margin-bottom: px(12);

    @include media-desktop {
      margin-bottom: px(24);
    }
  }

  h3 {
    margin-bottom: px(20);

    @include media-desktop {
      margin-bottom: px(32);
    }
  }

  h4,
  h5,
  h6 {
    margin-bottom: px(8);

    @include media-desktop {
      margin-bottom: px(12);
    }
  }

  p {
    margin-bottom: px(20);

    @include media-desktop {
      margin-bottom: px(25);
    }
  }

  :is(h1, h2, h3, h4, h5, h6, p) {
    &:global(.no-margin) {
      margin: 0;
    }

    &:global(.no-max-width) {
      max-width: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin general-max-width {
  :is(h3, h4, h5, h6, p) {
    max-width: $default-max-width;

    @include media-desktop {
      max-width: $default-max-width-desktop;
    }
  }
}

/// Detect hover-able devices
/// @example scss Input
///   element {
///     @detect-hover {
///        &:hover {
///         background-color: red;
///        }
///     }
///   }
@mixin detect-hover {
  @media (hover: hover) {
    @content;
  }
}

@mixin underline($color: $gold) {
  @include position-100(absolute);
  background-color: $color;
  height: 1px;
  top: calc(100% - 1px);
  transform: scaleX(0);
  transform-origin: left;
}

@mixin minimum-lines($lines: 1) {
  min-height: calc(1em * #{$lines});
}

@mixin min-height-by-breakpoints(
  $mobile: $min-height-cards-mobile,
  $tablet: $min-height-cards-tablet,
  $desktop: $min-height-cards-desktop
) {
  @if ($mobile) {
    min-height: $mobile;
  }

  @include media-tablet {
    @if ($tablet) {
      min-height: $tablet;
    }
  }

  @include media-desktop {
    @if ($desktop) {
      min-height: $desktop;
    }
  }
}

/// Clamp the amount of lines with css
/// @example scss Input
///   .elementWithTooMuchText {
///     @include text-ellipsis(3, 3, 2);
///   }
@mixin text-ellipsis($mobileLines: null, $tabletLines: null, $desktopLines: null) {
  @if ($mobileLines or $tabletLines or $desktopLines) {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix -- We need to keep -webkit prefix here */
  } @else {
    @error "You need to specify at least one line amount";
  }

  @if ($mobileLines) {
    -webkit-line-clamp: $mobileLines;
  }

  @include media-tablet {
    @if ($tabletLines) {
      -webkit-line-clamp: $tabletLines;
    }
  }

  @include media-desktop {
    @if ($desktopLines) {
      -webkit-line-clamp: $desktopLines;
    }
  }
}

@mixin discreet-scrollbar($withPadding: false) {
  --scrollbar-color: #{$gold};
  --scrollbar-track-color: #{$soft-grey};

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-track {
    // Track
    background: var(--scrollbar-track-color);
    border-top: 7px solid transparent;
    border-bottom: 8px solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb {
    // Handle
    background-color: var(--scrollbar-color);
    background-clip: padding-box;
    border-top: 6px solid transparent;
    border-bottom: 7px solid transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    // Handle on hover
    background-color: var(--scrollbar-color-hover);
  }
  // add padding left and right to scrollbar

  @if ($withPadding) {
    &::-webkit-scrollbar-button:end:increment {
      width: #{$side-padding-mobile};
      display: block;
    }
    &::-webkit-scrollbar-button:start:increment {
      width: #{$side-padding-mobile};
      display: block;
    }
  }

  /* Firefox */
  scrollbar-color: var(--scrollbar-color) var(--scrollbar-track-color);
  scrollbar-width: thin; // none
}

/// position, top, left, width, height shorthand
/// @example scss Input
///   element {
///     @include rect(absolute, 0, 0, 100%, 100%);
///   }
@mixin rect($position: null, $top: null, $left: null, $width: null, $height: null) {
  @if $position != null {
    position: $position;
  }

  @if $top != null {
    top: $top;
  }

  @if $left != null {
    left: $left;
  }

  @include box($width, $height);
}
