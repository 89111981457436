@import 'shared';

.Nav {
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(0);
  @include z-index(main-nav);
  height: $navbar-height-mobile;
  position: sticky;
  visibility: hidden;
  transition: transform $default-duration $default-ease;

  .overlay {
    z-index: 1;
    border-bottom: 1px solid $soft-grey;
    @include position-100(absolute);
    background-color: $white;
    transform: translateY(-100%);
    transition: opacity $default-duration $default-ease, transform $default-duration $default-ease;
  }

  &:not(.transparent) {
    .overlay {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @include media-desktop {
    gap: px(16);
    height: $navbar-height-desktop;
    padding: 0;
  }

  --hover-scale: scaleX(1);

  &.hidden {
    @include media-tablet {
      transform: translateY(-100%);
    }
  }

  &.transparent {
    --hover-scale: scaleX(0);
    border: none;

    &::before {
      content: '';
      @include position-100(absolute);
      height: 120%;
      background: linear-gradient(
        180deg,
        rgba($pure-black, 0.3) 50%,
        rgba($pure-black, 0.07) 80%,
        rgba($pure-black, 0) 95%
      );
    }

    .wrapper {
      ul {
        li {
          > a,
          button {
            color: $white;

            span.bottomLine {
              background: $gold;
            }
          }

          a:hover,
          &.activeRoute a,
          &.active a {
            color: $gold;
          }
        }

        .circle {
          background-color: rgba($soft-grey, 0);
          border-color: $white;

          svg {
            path {
              stroke: $white;
            }
          }
        }
      }

      .logo {
        position: relative;
        path {
          fill: $white;
        }
      }
    }
  }

  .skipToContent {
    position: absolute;
    top: 0;
    left: 0;
    height: fit-content;
    pointer-events: none;
    opacity: 0.0001;
  }

  .skipToContent:focus,
  .skipToContent:active {
    color: $white;
    background-color: $black;
    opacity: 1;
  }

  .logoCon {
    flex: 0.7;

    .logo {
      width: px(64);
      height: 100%;
      padding: px(8) 0;

      @include media-desktop {
        width: px(80);
        padding: px(16) 0 px(16) px(16);
      }
    }
  }

  > .wrapper {
    width: 100%;
    display: flex;
    height: 100%;
    @include wrapper;
    position: relative;
    gap: px(16);
    z-index: 2;

    > ul {
      flex: 1;
      @include flex-center-vert;

      &.routes {
        justify-content: flex-start;

        .logo {
          width: px(80);
          height: px(48);

          @include media-desktop {
            width: px(68);
            height: px(40);
          }
        }
      }

      > li {
        height: px(48);
        margin: 0 px(25);
        position: relative;
        text-align: left;

        &:empty {
          display: none;
        }

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }

        &:not(.isDropdown) {
          height: 100%;

          a {
            display: flex;
            align-items: center;
            padding: px(18) 0;
            height: 100%;
            position: relative;
          }
        }

        .bottomLine {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: px(2);
          background: $darker-gold;
          transition: transform $default-duration $default-ease;
          transform: scaleX(0);
        }

        a,
        button {
          text-align: left;
          @include typography-label;
          cursor: pointer;
          transition: color 0.3s $default-ease;
        }

        a:hover,
        &.activeRoute:not(.isDropdown) a,
        &.active:not(.isDropdown) a {
          color: $darker-gold;
        }

        &.activeRoute a,
        &.active a {
          .bottomLine {
            transform: var(--hover-scale);
          }
        }

        button {
          background: none;
          border: none;
          z-index: 1;
          display: flex;
          align-items: center;
          height: px(48);
          padding: 0;
          @include circle-icon(true, true);

          .circle {
            margin-left: px(10);

            svg {
              transition: transform $default-duration $default-ease;
            }
          }

          .svg {
            path {
              stroke-width: 2.5;
            }
          }
        }

        .hasChildren {
          padding: 0 px(16);
          margin-left: px(-16);
          margin-right: px(-16);
          position: relative;
          height: 100%;

          .overlay {
            @include position-100(absolute);
            transform: scaleY(0);
            transform-origin: top;
            transition: transform 0.534s $default-ease 0.1s, opacity 0.2s linear 0.1s;
            background-color: $white;
            border: 1px solid $soft-grey;
            border-radius: px(5);
            opacity: 0;
            pointer-events: none;
            height: px(146);
          }

          &.children-1 {
            .overlay {
              height: px(49*2);
            }
          }

          &.children-2 {
            .overlay {
              height: px(49*3);
            }
          }

          &.children-3 {
            .overlay {
              height: px(49*4);
            }
          }

          &.children-4 {
            .overlay {
              height: px(49*5);
            }
          }

          button {
            position: relative;
          }

          .childrenItems {
            z-index: 1;
            position: relative;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.334s $default-ease;
            color: $black;
            a {
              padding: px(18) 0;
              @include flex-center-vert;
              width: 100%;
              border-top: 1px solid $soft-grey;

              &.activeRoute {
                color: $darker-gold;
              }

              svg {
                transform: rotateZ(-90deg);
                width: px(12);
                margin-left: auto;
                margin-right: px(6);

                path {
                  stroke: $darker-gold;
                  stroke-width: 3.5;
                }
              }
            }
          }
        }
        &.isDropdown {
          &.activeRoute button,
          &.active button {
            color: $darker-gold;
          }
          &.active {
            .overlay {
              transition: transform 0.334s $default-ease, opacity 0.2s linear;
              transform: scaleY(1);
              opacity: 1;
            }

            .childrenItems {
              height: auto;
              opacity: 1;
              visibility: visible;
              transition: opacity 0.667s $default-ease;
              transition-delay: 0.16s;
            }

            .circle {
              background-color: $darker-gold;

              .svg {
                transform: rotateZ(180deg);
                path {
                  stroke: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}
